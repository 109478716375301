<template lang="pug">
    div
        template(v-if="userLanguage == 'fr'")
            div.question.block.col-9
                p.content Concernant toutes les évolutions ou pour reporter des bugs, écrivez-nous à 
                    a(@click="email()") knowledge@scor.com
                

        template(v-if="userLanguage == 'en'")
            div.question.block.col-9
                p.content For any suggestion of improvement or to report any issue, please write us : 
                    a(@click="email()") knowledge@scor.com

        template(v-if="userLanguage == 'zh-cn'")
            div.question.block.col-9
                p.content 如果您有任何改进建议或者遇到了问题，请联系我们：
                    a(@click="email()") knowledge@scor.com

</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({

    data() {
        return {
        }
    },

    computed: {
        userLanguage() {
            return this.$store.getters['language/getUserLanguage']
        }
    },

    methods: {
        email() {
            window.location.href = "mailto:knowledge@scor.com"
        },
    }

})
</script>

<style lang="scss" scoped>
@import './question.scss';
.question {
    cursor: default !important;
    a {
        cursor: pointer;
    }
    p {
        padding-top: 0px !important;
    }
}
</style>